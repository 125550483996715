<template>
  <section class="hot-product m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <HeaderBar :title="title">
      <router-link to="/zh/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
      </router-link>
    </HeaderBar>

    <goods-columns @cartClick="handleCart" :data="goodsList" :columns="2" style="margin-top:16px"></goods-columns>
    <p class="search-no" v-if='noMore'>暂无更多商品</p>  
    
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import HeaderBar    from '@/components/zh/m-header.vue'

import { getSectionTypeGoods } from '@/api/zh/index.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ GoodsColumns, MSku, MAnimate, CartFlex, HeaderBar },
  data(){
    return {
      recomnd   : 4,
      loading   : false,
      skuModal  : false,
      tabTop    : 44,
      dotAnimate: false,
      start     : false,
      start_dom : null,
      cartNum   : 0,
      scrollTop : false,
      title     : '',
      goodsList: [],
      page: 1,
      noMore: false,
      goodsId: '',
      classifyId: 0
    }
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getClassifyGoodsHandle()
        }
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)

      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getClassifyGoodsHandle() {
      // 获取分类下的商品
      this.islock   = true
      getSectionTypeGoods({type_id: this.$route.params.id, page: this.page, cid: this.$route.query.id}).then(res => {
        this.loading  = false
        this.islock   = false
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
        }else{
          this.noMore = true
        }
      })
    }
  },
  mounted() {
    this.getClassifyGoodsHandle()
    this.title = this.$route.query.title
    
    this.classifyId = this.$route.params.id
  },
  activated() {
    if(this.classifyId != this.$route.params.id) {
      // 重新获取数据
      
      this.goodsList = []
      this.getClassifyGoodsHandle()
      this.title = this.$route.query.title

      this.classifyId = this.$route.query.id
    }
  },
  watch: {
      title(val) {
          if(val) {
            this.title = val
            this.page = 1
          }
      }
  }
}
</script>

<style lang="less" scoped>
.hot-product {
    width: 100%;
    height: 100%;
    padding-top: 44px;
    background-color: #F5F9FF;
    overflow-x: hidden;
    overflow-y: auto;
}
.right-img {
  width:22px;
  height:22px;
  margin:6px;
}
.search-no {
  line-height:80px;
  text-align:center;
  color:#888;
  font-size:14px;
}
</style>